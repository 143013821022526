import classNames from 'classnames';
import moment from 'moment';
import 'moment/locale/it';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    bookFoodbox,
    getFoodboxCalendar,
    getFoodboxPickupPoints,
    getFoodboxSlots,
    setFoodboxDay, setFoodboxNote, setFoodboxSelected,
    sFoodboxCalendar,
    sFoodboxDayChoosed,
    sFoodboxDaySelected,
    sFoodboxErrorMessage, sFoodboxNote,
    sFoodboxPickupPointSelected,
    sFoodboxReserved, sFoodboxSelected,
    sFoodboxSlotSelected,
} from '../store/actions';
import {getFoodboxType} from '../store/reducers/Foodbox.reducer';
import FoodboxSlotSelector from './FoodboxSlotSelector';
import PickupPointSelector from './PickupPointSelector';

moment.locale('it');


export default function ReserveFoodbox() {
    const [now] = useState(moment());
    const [tomorrow] = useState(moment().add(1, 'days'));
    const dispatch = useDispatch();
    const calendar = useSelector(sFoodboxCalendar);
    const daySelected = useSelector(sFoodboxDaySelected);
    const dayChoosed = useSelector(sFoodboxDayChoosed);
    const foodboxSlotSelected = useSelector(sFoodboxSlotSelected);
    const foodboxPickupPointSelected = useSelector(sFoodboxPickupPointSelected);
    const foodboxSelected = useSelector(sFoodboxSelected);
    const foodboxNote = useSelector(sFoodboxNote);
    const foodboxErrorMessage = useSelector(sFoodboxErrorMessage);
    const foodboxReserved = useSelector(sFoodboxReserved);

    const selectDay = useCallback(function(choose) {
        dispatch(setFoodboxDay(choose));
    }, [calendar]);

    const setNote = useCallback(function(e) {
        dispatch(setFoodboxNote(e.target.value));
    }, []);

    const reserveFoodbox = useCallback(function(e) {
        console.log(e)
        e && e.preventDefault && e.preventDefault();
        dispatch(bookFoodbox());
    }, [dispatch]);

    const selectFoodbox = useCallback(function(foodbox) {
        dispatch(setFoodboxSelected(foodbox));
    }, []);

    useEffect(function() {
        dispatch(getFoodboxCalendar());
        return () => {
            dispatch(getFoodboxCalendar());
        }
    }, []);

    useEffect(function() {
        dispatch(getFoodboxSlots());
        dispatch(getFoodboxPickupPoints());
    }, [daySelected]);

    if(!calendar.today || !calendar.tomorrow) {
        return null
    }
    const {
        has_canteen: has_canteen_today,
        has_foodbox :has_foodbox_today,
    } = calendar.today;
    const {
        has_canteen: has_canteen_tomorrow,
        has_foodbox :has_foodbox_tomorrow,
    } = calendar.tomorrow;

    const today22 = moment().hours(22).minutes(0).seconds(0);
    const today10 = moment().hours(10).minutes(0).seconds(0);

    const justBooked_today = has_canteen_today || has_foodbox_today;
    const justBooked_tomorrow = has_canteen_tomorrow || has_foodbox_tomorrow;
    const available_today = (today10 - now) > 0;
    // Tomorrow it is always possible to book the EasyBox
    const available_tomorrow = true || (today22 - now) > 0;

    return(

        <div
            className="flex flex-col w-full px-4 py-8 bg-white rounded-lg shadow dark:bg-gray-700 sm:px-6 md:px-8 lg:px-10">
            <div
                className="self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl text-black dark:text-white">
                Prenota La FoodBox
            </div>
            <fieldset>
                {!foodboxReserved && <div>
                    <legend
                        className="text-base font-bold text-black dark:text-white">Compila i modulo per prenotare la foodbox
                    </legend>
                </div>}

                {foodboxReserved && <div>
                    <legend
                        className="text-base font-bold text-black dark:text-white">Prenotazione effettuata correttamente
                    </legend>
                </div>}
                {(!dayChoosed || (daySelected && (daySelected.has_canteen || daySelected.has_foodbox))) && (
                    <div className="mt-4 space-y-4">
                        <p className="text-md text-black dark:text-white">
                            Seleziona il giorno
                        </p>
                        {(available_today || justBooked_today) && <div
                            className="flex items-center">
                            <input id="push-everything"
                                   name="push-notifications"
                                   type="radio"
                                   className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                   onChange={() => selectDay('today')}
                            />
                            <label
                                htmlFor="push-everything"
                                className="ml-3 block text-sm font-medium text-black dark:text-white"> Oggi </label>
                        </div>}
                        <div
                            className="flex items-center">
                            <input id="push-nothing"
                                   name="push-notifications"
                                   type="radio"
                                   className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                   onChange={() => selectDay('tomorrow')}
                            />
                            <label
                                htmlFor="push-nothing"
                                className="ml-3 block text-sm font-medium text-black dark:text-white"> Domani </label>
                        </div>
                        {(daySelected && (daySelected.has_canteen || daySelected.has_foodbox)) && (
                            <div className="mt-4 space-y-4">
                                <p className="text-md text-red-700 border-b-2 pb-2">
                                    Hai già una prenotazione per {dayChoosed === 'today' ? now.format('dddd, DD MMMM yyyy') :
                                    tomorrow.format('dddd, DD MMMM yyyy')}
                                </p>
                            </div>
                        )}
                    </div>
                ) }

                {(dayChoosed && daySelected && !daySelected.has_canteen && !daySelected.has_foodbox && !foodboxReserved) && (
                    <div className="mt-4 space-y-4">
                        <p className="text-md text-black dark:text-white border-b-2 pb-2">
                            Stai prenotando per: <br/>
                            {dayChoosed === 'today' ? now.format('dddd, DD MMMM yyyy') :
                                tomorrow.format('dddd, DD MMMM yyyy')}
                        </p>
                    </div>
                )}

                {(dayChoosed && daySelected && !daySelected.has_canteen && !daySelected.has_foodbox && !foodboxSlotSelected && !foodboxReserved) && (
                    <FoodboxSlotSelector />
                )}

                {(dayChoosed && daySelected && !daySelected.has_canteen && !daySelected.has_foodbox && foodboxSlotSelected && !foodboxReserved) && (
                    <div className="mt-4 space-y-4">
                        <p className="text-md text-black dark:text-white border-b-2 pb-2">
                            Hai selezionato lo slot: <br/>
                            {`${foodboxSlotSelected.start.substring(0,5)} - ${foodboxSlotSelected.end.substring(0,5)}`}
                        </p>
                    </div>
                )}

                {(dayChoosed && daySelected && !daySelected.has_canteen && !daySelected.has_foodbox && foodboxSlotSelected && !foodboxPickupPointSelected && !foodboxReserved) && (
                    <PickupPointSelector />
                )}

                {(dayChoosed && daySelected && !daySelected.has_canteen && !daySelected.has_foodbox && foodboxSlotSelected && foodboxPickupPointSelected && !foodboxReserved) && (
                    <div className="mt-4 space-y-4">
                        <p className="text-md text-black dark:text-white border-b-2 pb-2">
                            Hai selezionato il punto di ritiro: <br/>
                            {foodboxPickupPointSelected.name}
                        </p>
                    </div>
                )}

                {(dayChoosed && daySelected && !daySelected.has_canteen && !daySelected.has_foodbox && foodboxSlotSelected && foodboxPickupPointSelected && !foodboxReserved) && (
                    <div className={classNames('mt-4 space-y-4', {'border-b-2': foodboxSelected, 'pb-2': foodboxSelected})} >
                        <p className={classNames('text-md text-black dark:text-white')}>
                            Seleziona la tipologia di foodbox
                        </p>
                        {foodboxPickupPointSelected && <>
                            {calendar[dayChoosed].foodboxes.filter(foodbox => {
                                if (dayChoosed === 'today') {
                                    if (foodbox.type !== 3 && foodbox.type !== 4) {
                                        return false;
                                    } else {
                                        return available_today;
                                    }
                                } else if (dayChoosed === 'tomorrow') {
                                    if (foodbox.type !== 3 && foodbox.type !== 4) {
                                        return today22 - now > 0;
                                    } else {
                                        return true;
                                    }
                                }
                            }).map(foodbox => {
                                return (
                                    <div
                                        key={foodbox.id}
                                        className="flex items-center">
                                        <input id={`foodbox-choose-${foodbox.id}`}
                                               name="foodbox-choose"
                                               type="radio"
                                               value={foodbox.id}
                                               className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                               onChange={() => selectFoodbox(foodbox)}
                                        />
                                        <label
                                            htmlFor={`foodbox-choose-${foodbox.id}`}
                                            className="ml-3 block text-sm font-medium text-black dark:text-white"> {getFoodboxType(foodbox.type)} </label>
                                    </div>
                                );
                            })}

                        </>}
                    </div>
                )}
                {(!foodboxReserved && foodboxSelected) && (
                    <div className={"container mt-4"}>
                        <p className={"text-base font-bold text-black dark:text-white mb-4"}> Inserisci una nota </p>
                        <textarea placeholder={"Inserisci nota"} style={{display: 'block', resize: 'none', outline: 'none'}} className={"container px-3 py-1.5 border border-solid border-gray-300  rounded mb-4"}
                            value={foodboxNote}
                            onInput={setNote}
                        />
                        <button onClick={reserveFoodbox}
                                className={"text-sm p-1 text-gray-400 border rounded border-gray-400 mr-4"}> Prenota </button>
                    </div>
                )}

                {(foodboxErrorMessage) && (
                    <div className="mt-4 space-y-4">
                        <p className="text-md text-red-700 border-b-2 pb-2">
                            {foodboxErrorMessage}
                        </p>
                    </div>
                )}
            </fieldset>

        </div>
    );

}
