import moment from 'moment';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    getCanteenAvaiableSeats,
    getCanteenCalendar,
    getCanteenFreeSlot,
    sCanteenDaySelected, sCanteenReserved,
    sCanteenSeats,
    sCanteenSeatsFetching,
    sCanteenSlotSelected, setCanteenReservationActive, setCanteenSlotSelected,
} from '../store/actions';
import CanteenDaySelector from './CanteenDaySelector';
import CanteenSlotSelector from './CanteenSlotSelector';

function ReserveCanteen() {
    const dispatch = useDispatch();
    const canteenDaySelected = useSelector(sCanteenDaySelected);
    const canteenSlotSelected = useSelector(sCanteenSlotSelected);
    const canteenSeats = useSelector(sCanteenSeats);
    const canteenSeatsFetching = useSelector(sCanteenSeatsFetching);
    const canteenReserved = useSelector(sCanteenReserved);

    useEffect(function() {
        dispatch(getCanteenCalendar());
        return () => {
            // dispatch(getCanteenCalendar());
        }
    }, []);

    useEffect(function() {
        if (canteenDaySelected) {
            dispatch(getCanteenFreeSlot());
        }
    }, [canteenDaySelected]);

    useEffect(function() {
        if (canteenSlotSelected) {
            dispatch(getCanteenAvaiableSeats());
        }
    }, [canteenSlotSelected]);

    const dayBookable = canteenDaySelected && canteenDaySelected.bookable &&
        !canteenDaySelected.isFull && !canteenDaySelected.just_booked &&
        !canteenDaySelected.hasFoodboxBooking;
    const isCanteenReserved = !!!!canteenReserved;

    return (
        <div
            className="flex flex-col w-full px-4 py-8 bg-white rounded-lg shadow dark:bg-gray-700 sm:px-6 md:px-8 lg:px-10">
            <div
                className="self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl text-black dark:text-white">
                Prenota il posto in mensa
            </div>
            <fieldset>
                {!isCanteenReserved && <div>
                    <legend
                        className="text-base font-bold text-black dark:text-white">Compila il modulo per prenotare il posto in mensa
                    </legend>
                </div>}

                {isCanteenReserved && <div>
                    <legend
                        className="text-base font-bold text-black dark:text-white">Prenotazione effettuata correttamente
                    </legend>
                </div>}
                {(!isCanteenReserved && (!canteenDaySelected || !dayBookable))  && <CanteenDaySelector/>}
                {(canteenDaySelected && canteenDaySelected.isFull) && (
                    <div className="mt-4 space-y-4">
                        <p className="text-md text-black dark:text-white border-b-2 pb-2">
                            Non puoi effettuare prenotazioni in questa data
                        </p>
                    </div>
                )}
                {(!isCanteenReserved && canteenDaySelected && (canteenDaySelected.just_booked || canteenDaySelected.hasFoodboxBooking)) && (
                    <div className="mt-4 space-y-4">
                        <p className="text-md text-black dark:text-white border-b-2 pb-2">
                            Hai già una prenotazione per questa data
                        </p>
                    </div>
                )}
                {(!isCanteenReserved && canteenDaySelected && dayBookable) && (
                    <div className="mt-4 space-y-4">
                        <p className="text-md text-black dark:text-white border-b-2 pb-2">
                            Hai selezionato il giorno: <br/>
                            {moment(canteenDaySelected.date).format('DD-MM-YYYY')}
                        </p>
                    </div>
                )}
                {(!isCanteenReserved && canteenDaySelected && dayBookable && !canteenSlotSelected) && <CanteenSlotSelector />}
                {(!isCanteenReserved && canteenSlotSelected) && (
                    <div className="mt-4 space-y-4">
                        <p className="text-md text-black dark:text-white border-b-2 pb-2">
                            Hai selezionato il giorno: <br/>
                            {canteenSlotSelected.name}
                        </p>
                    </div>
                )}
                {(!isCanteenReserved && canteenSlotSelected && !canteenSeatsFetching && canteenSeats.length === 0)  && (
                    <div className="flex items-center justify-between mt-4 ">
                        <p className="text-md text-black dark:text-white ">
                            Non ci sono posti liberi in questo slot
                        </p>
                        <button
                            className="text-sm p-1 text-gray-400 border rounded border-gray-400 mr-4"
                            onClick={() => dispatch(setCanteenSlotSelected(null))}>
                            Scegli altro slot
                        </button>
                    </div>
                )}
            </fieldset>
        </div>
    );
}
export default ReserveCanteen;
