import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {sCanteenCalendar, setCanteenDaySelected} from '../store/actions';

export default function CanteenDaySelector({}) {
    const canteenCalendar = useSelector(sCanteenCalendar);
    const dispatch = useDispatch();
    return (
        <div className="mt-4 space-y-4">
            <p className="text-md text-black dark:text-white">
                Seleziona il giorno
            </p>
            {canteenCalendar.map(
                i => {
                    const day = moment(i.date);

                    return (
                        <div
                            key={i.id}
                            className="flex items-center">
                            <input id={`canteen-day-${i.id}`}
                                   name={`canteen-day`}
                                   type="radio"
                                   className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                   onChange={() => dispatch(setCanteenDaySelected(i))}
                            />
                            <label
                                htmlFor={`canteen-day-${i.id}`}
                                className="ml-3 block text-sm font-medium text-black dark:text-white"> {day.format('DD-MM-YYYY')} </label>
                        </div>
                    );
                })}
        </div>
    );
}
