import {persistReducer} from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import storage from 'redux-persist/lib/storage/session';
import {ActionType} from 'redux-promise-middleware';

import {APP_INITIALIZE} from '../actions';
import {USER_BOOKING_FETCH} from '../actions/Booking.actions';

const INITIAL_STATE = {
    bookings: [],
};
const BookingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case `${USER_BOOKING_FETCH}_${ActionType.Fulfilled}`:
            return {
                ...state,
                bookings: action.payload
            }
        default:
            return state;
    }
};

const persistConfig = {
    key: 'booking',
    whitelist: [
        'bookings'
    ],
    storage,
};
const persistedReducer = persistReducer(persistConfig, BookingReducer);

export default persistedReducer;
