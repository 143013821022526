import {combineReducers} from 'redux';

import app from './App.reducer';
import auth from './Auth.reducer';
import foodBox from './Foodbox.reducer';
import booking from './Booking.reducer';
import canteen from './Canteen.reducer';

const rootReducer = combineReducers({
    auth,
    app,
    foodBox,
    booking,
    canteen
});

export default rootReducer;
