/* global __DEV__ */
import axios from 'axios';
//
const CLIENT_ID = 4;
const CLIENT_SECRET = 'fYfC1j3jWysroy6CdefL9ubTE1oErDldhaUbWKGs';

const BASE_URL_PROD = 'https://faterhub.fatergroup.com/api';
const BASE_URL_DEV = '/proxy/api';

const instanceLogin = axios.create({
    baseURL: (__DEV__ ? BASE_URL_DEV : BASE_URL_PROD)+'/FE',
    timeout: 300000,
    headers: {
        Accept: 'application/json',
    },
});
const instance = axios.create({
    baseURL: (__DEV__ ? BASE_URL_DEV : BASE_URL_PROD)+'/v1',
    timeout: 300000,
    headers: {
        Accept: 'application/json',
    },
});

class Api {
    static login(email, password) {
        return instanceLogin.post('/connect', {
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            grant_type: 'password',
            email,
            password,
            scope: '',
        }).then((res) => res.data);
    }

    static getMyBooking(accessToken) {
        return instance.get('/me/bookings', {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        }).then(response => response.data)
            .then(response => response.data);
    }

    static getFoodboxCalendar(accessToken) {
        return instance.get('foodbox/calendar', {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        }).then(response => response.data);
    }

    static getFoodboxSlots(accessToken) {
        return instance.get('foodbox/slots', {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        }).then(response => response.data);
    }

    static getFoodboxPickupPoints(accessToken) {
        return instance.get('foodbox/pickup-points', {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        }).then(response => response.data);
    }

    static getFoodboxBooking(accessToken) {
        return instance.get('foodbox/my-booking', {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        }).then(response => response.data);
    }

    static bookFoodbox(accessToken, slot_id, foodbox_id, choose, pickup_point_id, note) {
        return instance.post('foodbox/reserve', {
            slot_id, foodbox_id, choose,
            pickup_point_id, note
        }, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        }).then(response => response.data);
    }

    static cancelBookFoodbox(accessToken, foodbox_booking_id) {
        return instance.delete('foodbox/cancel', {
            params: {
                foodbox_booking_id
            },
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        });
    }

    static getCanteenCalendar(accessToken) {
        return instance.get(`/booking/canteen/calendar/free-slots`, {
            params: {
                office_id: 3
            },
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        })
            .then(response => response.data)
            .then(response => {
                return response.data.filter(day => day.day_in_range);
                    // .reduce(function(acc, day) {
                    //     acc[day.date] = day;
                    //     return acc;
                    // }, {});
            });
    }

    static getCanteenDaySlots(accessToken, day, canteen_id) {
        return instance.get(`/booking/canteen/slots/date`, {
            params:{
                date: day,
                canteen_id
            },
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        })
            .then(response => response.data)
            .then(response => response.data);
    }

    static getCanteenAvailableSeats(accessToken, date, slot_id, office_id) {
        return instance.get(`/booking/canteen/available-seats`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
            params: {
                slot_id, office_id, date,
            },
        }).then(response => response.data)
            .then(response => response.data);
    }

    static bookCanteen(accessToken, {date, slot_id, codeSeat, office_id}) {
        return instance.post(`/booking/canteen/reserve/control`, {
            slot_id, date, code: codeSeat, office_id
        }, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        })
            .then(response => response.data)
            .then(response => response.data);
    }

    static deleteBooking(accessToken, ticket) {
        return instance.delete('/booking/canteen/cancel', {
            params: {
                booking_id: ticket.id
            },
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        }).then(response => (ticket));
    }
}

export default Api;
