import {useDispatch, useSelector} from 'react-redux';
import {setFoodboxPickupPoint, sFoodboxPickupPoints} from '../store/actions';

export default function PickupPointSelector({}) {
    const foodboxPickupPoints = useSelector(sFoodboxPickupPoints);
    const dispatch = useDispatch();
    return (

        <div className="mt-4 space-y-4">
            <p className="text-md text-black dark:text-white">
                Seleziona lo slot orario per il ritiro
            </p>
            {foodboxPickupPoints.map(
                i => {
                    return (
                        <div
                            key={i.id}
                            className="flex items-center">
                            <input id={`foodbox-pick-${i.id}`}
                                   name={`foodbox-pick`}
                                   type="radio"
                                   className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                   onChange={() => dispatch(setFoodboxPickupPoint(i))}
                            />
                            <label
                                htmlFor={`foodbox-pick-${i.id}`}
                                className="ml-3 block text-sm font-medium text-black dark:text-white"> {i.name} </label>
                        </div>
                    );
                })}
        </div>
    );
}
