import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import {login} from '../store/actions';

export function Login() {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const loading = useSelector(state => state.auth.loading);
    const error = useSelector(state => state.auth.error);

    const dispatch = useDispatch();

    const handleLogin = useCallback(function() {
        dispatch(login(username, password));
    }, [username, password]);

    return (
        <main
            className="flex w-full flex-1 flex-col items-center justify-center text-center">
            <section className="h-full gradient-form bg-gray-200 md:h-screen">
                <div className="w-full py-12 px-6 h-full">
                    <div
                        className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
                        <div className="xl:w-10/12">
                            <div
                                className="block bg-white shadow-lg rounded-lg">
                                <div className="lg:flex lg:flex-wrap g-0">
                                    <div className="lg:w-6/12 px-4 md:px-0">
                                        <div className="p-12 md:mx-6">
                                            <div className="text-center">
                                                <h4 className="text-xl font-semibold mt-1 mb-12 pb-1">
                                                    Prenota la mensa del plant
                                                </h4>
                                            </div>
                                            <form>
                                                <p className="mb-4">Accedi con
                                                    il tuo account</p>
                                                <div className="mb-4">
                                                    <input
                                                        type="text"
                                                        value={username}
                                                        onChange={e => {
                                                            setUsername(
                                                                e.target.value);
                                                        }}
                                                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                        id="exampleFormControlInput1"
                                                        placeholder="Username"
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <input
                                                        type="password"
                                                        value={password}
                                                        onChange={e => {
                                                            setPassword(
                                                                e.target.value);
                                                        }}
                                                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                        id="exampleFormControlInput1"
                                                        placeholder="Password"
                                                    />

                                                </div>
                                                {error && <div className="mb-4">
                                                    <p className="text-red-500 text-xs italic">Inserisci
                                                        delle credenziali
                                                        valide.</p>
                                                </div>}
                                                <div
                                                    className="text-center pt-1 mb-12 pb-1">
                                                    <button
                                                        className={classNames(
                                                            'inline-block px-6 py-2.5 text-black dark:text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full mb-3',
                                                            {'animate-pulse': loading})}
                                                        type="button"
                                                        data-mdb-ripple="true"
                                                        data-mdb-ripple-color="light"
                                                        style={{background: 'linear-gradient(to right,#ee7724,#d8363a,#dd3675,#b44593)'}}
                                                        onClick={handleLogin}
                                                    >
                                                        {loading && <>...
                                                            caricamento</>}
                                                        {!loading && <>Login</>}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div
                                        className="lg:w-6/12 flex items-center lg:rounded-r-lg rounded-b-lg lg:rounded-bl-none"
                                        style={{background: 'linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593)'}}
                                    >
                                        <div
                                            className="text-black dark:text-white px-4 py-6 md:p-12 md:mx-6">
                                            <h4 className="text-xl font-semibold mb-6">
                                                Accedi al portale e prenota il
                                                posto in mensa o la foodbox
                                            </h4>
                                            <p className="text-sm">
                                                Oltre alle funzionalità già
                                                disponibili in Charlie avrai la
                                                possibilità di prenotare il tuo
                                                posto mensa o la tua foodbox per
                                                il Plant di Via Raiale. Ricorda
                                                che la prenotazione Foodbox
                                                dovrà esser fatta il giorno
                                                precedente o al massimo entro le
                                                10.00 del giorno di ritiro per
                                                la sola Foodbox Easy.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
