import {ActionType} from 'redux-promise-middleware';
import {
    FOODBOX_BOOKING,
    GET_FOODBOX_CALENDAR,
    GET_FOODBOX_PICKUP_POINTS,
    GET_FOODBOX_SLOTS,
    RESET_FOODBOX_ERROR,
    SET_FOODBOX_DAY,
    SET_FOODBOX_PICKUP_POINT,
    SET_FOODBOX_SLOT,
    BOOK_FOODBOX,
    SET_FOODBOX_RESERVATION_ACTIVE,
    SET_FOODBOX_SELECTED,
    SET_FOODBOX_NOTE,
} from '../actions/FoodBox.actions';

const INITIAL_STATE = {
    foodBoxReservationActive: false,
    foodboxCalendar: {},
    foodboxDayChoosed: null,
    foodboxDaySelected: null,
    foodboxSlots: [],
    foodboxSlotSelected: null,
    foodboxPickupPoints: [],
    foodboxPickupPointSelected: null,
    foodboxSelected: null,
    foodboxNote: '',
    foodboxBookingFetching: '',
    foodboxErrorMessage: '',
    foodboxReserved: null,
    foodboxBooking: [],
};

const FOODBOX_TYPE = {
    "1": "FooxBox A - Classico",
    "2": "FooxBox B - Vegetariano",
    "3": "FooxBox C - Easy",
    "4": "FooxBox D - Easy",
}
export function getFoodboxType(type) {
    return FOODBOX_TYPE[type] ?? 'FooxBox';
};
const FoodboxReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_FOODBOX_RESERVATION_ACTIVE:
            return {
                ...state,
                foodBoxReservationActive: action.payload,
                foodboxDayChoosed: null,
                foodboxDaySelected: null,
                foodboxSlots: [],
                foodboxSlotSelected: null,
                foodboxPickupPoints: [],
                foodboxPickupPointSelected: null,
                foodboxSelected: null,
                foodboxNote: '',
                foodboxReserved: null,
            }
        case `${GET_FOODBOX_CALENDAR}_${ActionType.Fulfilled}`:
            return {
                ...state,
                foodboxCalendar: action.payload,
                foodboxDayChoosed: null,
                foodboxDaySelected: null,
                foodboxSlots: [],
                foodboxSlotSelected: null,
                foodboxPickupPoints: [],
                foodboxPickupPointSelected: null,
                foodboxSelected: null,
                foodboxNote: '',
                foodboxReserved: null,
            };
        case SET_FOODBOX_DAY:
            return {
                ...state,
                foodboxDayChoosed: action.payload,
                foodboxDaySelected: state.foodboxCalendar[action.payload],
            };
        case `${GET_FOODBOX_SLOTS}_${ActionType.Fulfilled}`:
            return {
                ...state,
                foodboxSlots: action.payload,
            };
        case SET_FOODBOX_SLOT:
            return {
                ...state,
                foodboxSlotSelected: action.payload,
            };
        case `${GET_FOODBOX_PICKUP_POINTS}_${ActionType.Fulfilled}`:
            return {
                ...state,
                foodboxPickupPoints: action.payload,
            };
        case SET_FOODBOX_PICKUP_POINT:
            return {
                ...state,
                foodboxPickupPointSelected: action.payload,
            };
        case SET_FOODBOX_SELECTED:
            return {
                ...state,
                foodboxSelected: action.payload
            }
        case SET_FOODBOX_NOTE:
            return {
                ...state,
                foodboxNote: action.payload
            }
        case `${BOOK_FOODBOX}_${ActionType.Pending}`:
            return {
                ...state,
                foodboxBookingFetching: true,
            }
        case `${BOOK_FOODBOX}_${ActionType.Fulfilled}`:
            return {
                ...state,
                foodboxReserved: {
                    ...action.payload,
                    foodBox_name: getFoodboxType(action.payload.type),
                    date: action.payload.day,
                    // hours: translate('services.bookFoodbox.' + (action.payload.foodbox_slot_id ? 'lunch': 'dinner'))
                },
                foodboxBookingFetching: false,
            }
            return state;
        case `${BOOK_FOODBOX}_${ActionType.Rejected}`:
            switch (action.payload.response.status) {
                case 434:
                case 435:
                    return {
                        ...state,
                        foodboxDayChoosed: null,
                        foodboxDaySelected: null,
                        foodboxSlots: [],
                        foodboxSlotSelected: null,
                        foodboxPickupPoints: [],
                        foodboxPickupPointSelected: null,
                        foodboxSelected: null,
                        foodboxNote: '',
                        foodboxBookingFetching: false,
                        foodboxErrorMessage: "Hai già una prenotazione"
                    }
                case 436:
                    return {
                        ...state,
                        foodboxDayChoosed: null,
                        foodboxDaySelected: null,
                        foodboxSlots: [],
                        foodboxSlotSelected: null,
                        foodboxPickupPoints: [],
                        foodboxPickupPointSelected: null,
                        foodboxSelected: null,
                        foodboxNote: '',
                        foodboxBookingFetching: false,
                        foodboxErrorMessage: "Non è più prenotabile"
                    }
                case 403:
                case 404:
                    return {
                        ...state,
                        foodboxDayChoosed: null,
                        foodboxDaySelected: null,
                        foodboxSlots: [],
                        foodboxSlotSelected: null,
                        foodboxPickupPoints: [],
                        foodboxPickupPointSelected: null,
                        foodboxSelected: null,
                        foodboxNote: '',
                        foodboxBookingFetching: false,
                        foodboxErrorMessage: "Non puoi prenotare una foodbox"
                    }
            }
            return state;
        case RESET_FOODBOX_ERROR:
            return {
                ...state,
                foodboxErrorMessage: ''
            }
        case `${FOODBOX_BOOKING}_${ActionType.Fulfilled}`:
            return {
                ...state,
                foodboxBooking: action.payload.map(fb => ({
                    ...fb,
                    foodBox_name: getFoodboxType(fb.type),
                    date: fb.day,
                }))
            }
        default:
            return state;
    }
};

export default FoodboxReducer;
