import Api from '../../api/Api';

export const SET_FOODBOX_RESERVATION_ACTIVE = 'SET_FOODBOX_RESERVATION_ACTIVE';
export const GET_FOODBOX_CALENDAR = 'GET_FOODBOX_CALENDAR';
export const SET_FOODBOX_DAY = 'SET_FOODBOX_DAY';
export const GET_FOODBOX_SLOTS = 'GET_FOODBOX_SLOTS';
export const SET_FOODBOX_SLOT = 'SET_FOODBOX_SLOT';
export const GET_FOODBOX_PICKUP_POINTS = 'GET_FOODBOX_PICKUP_POINTS';
export const SET_FOODBOX_PICKUP_POINT = 'SET_FOODBOX_PICKUP_POINT';
export const SET_FOODBOX_SELECTED = 'SET_FOODBOX_SELECTED';
export const SET_FOODBOX_NOTE = 'SET_FOODBOX_NOTE';
export const RESET_FOODBOX_ERROR = 'RESET_FOODBOX_ERROR';
export const BOOK_FOODBOX = 'BOOK_FOODBOX';
export const FOODBOX_BOOKING = 'FOODBOX_BOOKING';
export const DELETE_FOODBOX_BOOKING = 'DELETE_FOODBOX_BOOKING';


export const sBooking = (state) => state.foodBox;
export const sFoodboxReservationActive = (state) => sBooking(state).foodBoxReservationActive;
export const sFoodboxCalendar = (state) => sBooking(state).foodboxCalendar;
export const sFoodboxDaySelected = (state) => sBooking(state).foodboxDaySelected;
export const sFoodboxDayChoosed = (state) => sBooking(state).foodboxDayChoosed;
export const sFoodboxSlots = (state) => sBooking(state).foodboxSlots;
export const sFoodboxSlotSelected = (state) => sBooking(state).foodboxSlotSelected;
export const sFoodboxPickupPoints = (state) => sBooking(state).foodboxPickupPoints;
export const sFoodboxPickupPointSelected = (state) => sBooking(state).foodboxPickupPointSelected;
export const sFoodboxSelected = (state) => sBooking(state).foodboxSelected;
export const sFoodboxNote = (state) => sBooking(state).foodboxNote;
export const sFoodboxFetching = (state) => sBooking(state).foodboxBookingFetching;
export const sFoodboxErrorMessage = (state) => sBooking(state).foodboxErrorMessage;
export const sFoodboxReserved = (state) => sBooking(state).foodboxReserved;
export const sFoodboxBooking = (state) => sBooking(state).foodboxBooking;
export const sAccessToken = (state) => state.auth.user.token;

export function setFoodboxReservationActive(active) {
    return {
        type: SET_FOODBOX_RESERVATION_ACTIVE,
        payload: active
    }
}

export function getFoodboxCalendar() {
    return (dispatch, getState) => {
        const token = sAccessToken(getState());
        dispatch({
            type: GET_FOODBOX_CALENDAR,
            payload: Api.getFoodboxCalendar(token)
        });
    }
}
export function setFoodboxDay(choose) {
    return {
        type: SET_FOODBOX_DAY,
        payload: choose
    }
}
export function getFoodboxSlots() {
    return (dispatch, getState) => {
        const token = sAccessToken(getState());
        dispatch({
            type: GET_FOODBOX_SLOTS,
            payload: Api.getFoodboxSlots(token)
        });
    }
}
export function getFoodboxPickupPoints() {
    return (dispatch, getState) => {
        const token = sAccessToken(getState());
        dispatch({
            type: GET_FOODBOX_PICKUP_POINTS,
            payload: Api.getFoodboxPickupPoints(token)
        });
    }
}
export function setFoodboxSlot(slot) {
    return {
        type: SET_FOODBOX_SLOT,
        payload: slot
    }
}
export function setFoodboxPickupPoint(pickupPoint) {
    return {
        type: SET_FOODBOX_PICKUP_POINT,
        payload: pickupPoint
    }
}
export function setFoodboxSelected(foodbox) {
    return {
        type: SET_FOODBOX_SELECTED,
        payload: foodbox
    }
}
export function setFoodboxNote(note) {
    return {
        type: SET_FOODBOX_NOTE,
        payload: note
    }
}
export function bookFoodbox() {
    return (dispatch, getState) => {
        const state = getState();
        const fetching = sFoodboxFetching(state);
        if (fetching) {
            return;
        }
        const token = sAccessToken(state);
        const slot = sFoodboxSlotSelected(state);
        const choose = sFoodboxDayChoosed(state);
        const foodbox = sFoodboxSelected(state);
        const note = sFoodboxNote(state);
        const pickupPoint = sFoodboxPickupPointSelected(state);
        dispatch({
            type: BOOK_FOODBOX,
            payload: Api.bookFoodbox(token, slot.id, foodbox.id, choose, pickupPoint.id, note)
        }).then(function() {
            dispatch(getFoodboxBooking());
            setTimeout(function() {
                dispatch(setFoodboxReservationActive(false));
            }, 3000);
        }).finally(function() {
            setTimeout(function() {
                dispatch(resetFoodboxError());
            }, 3000);
        });
    }
}
export function resetFoodboxError() {
    return {
        type: RESET_FOODBOX_ERROR
    }
}
export function getFoodboxBooking() {
    return (dispatch, getState) => {
        const token = sAccessToken(getState());
        dispatch({
            type: FOODBOX_BOOKING,
            payload: Api.getFoodboxBooking(token)
        });
    }
}
export function deleteFoodboxBooking(reservation) {
    return (dispatch, getState) => {
        const token = sAccessToken(getState());
        dispatch({
            type: DELETE_FOODBOX_BOOKING,
            payload: Api.cancelBookFoodbox(token, reservation.id)
        }).finally(function() {
            dispatch(getFoodboxBooking());
            dispatch(setFoodboxReservationActive(false));
        });
    }
}

