import {useDispatch, useSelector} from 'react-redux';
import {setFoodboxSlot, sFoodboxSlots} from '../store/actions';

export default function FoodboxSlotSelector({}) {
    const foodboxSlots = useSelector(sFoodboxSlots);
    const dispatch = useDispatch();
    return (

        <div className="mt-4 space-y-4">
            <p className="text-md text-black dark:text-white">
                Seleziona lo slot orario per il ritiro
            </p>
            {foodboxSlots.map(
                i => {
                    return (
                        <div
                            key={i.id}
                            className="flex items-center">
                            <input id={`foodbox-slot-${i.id}`}
                                   name={`foodbox-slot`}
                                   type="radio"
                                   className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                   onChange={() => dispatch(setFoodboxSlot(i))}
                            />
                            <label
                                htmlFor={`foodbox-slot-${i.id}`}
                                className="ml-3 block text-sm font-medium text-black dark:text-white"> {`${i.start.substring(
                                0, 5)} - ${i.end.substring(0, 5)}`} </label>
                        </div>
                    );
                })}
        </div>
    );
}
