import {persistReducer} from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import storage from 'redux-persist/lib/storage/session';

import {APP_INITIALIZE} from '../actions';

const INITIAL_STATE = {
    initialized: false,

};
const AppReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case `${APP_INITIALIZE}_FULFILLED`:
            return {
                ...state,
            };
        case 'REHYDRATATION_COMPLETE':
            return {
                ...state,
                initialized: true
            }
        default:
            return state;
    }
};

export default AppReducer;
