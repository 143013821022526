import {createSelector} from 'reselect';
import Api from '../../api/Api';
import {sAccessToken} from './index';

export const USER_BOOKING_FETCH = 'USER_BOOKING_FETCH';

const sBooking = (state) => state.booking;
const sUserBookings = (state) => sBooking(state).bookings;

export const sUserBookingsWithoutGuests = createSelector(
    sUserBookings,
    (bookings) => {
        return bookings.filter(function(item) {
            return !item.is_guest;
        });
    }
);

export const sUserBookingsCanteen = createSelector(
    sUserBookingsWithoutGuests,
    (bookings) => {
        return bookings.filter(function(item) {
            return item.type === 'canteen';
        });
    });

export function getMyBookings() {
    return async function(dispatch, getState) {
        const token = sAccessToken(getState());
        return dispatch({
            type: USER_BOOKING_FETCH,
            payload: Api.getMyBooking(token)
        });
    }
}
