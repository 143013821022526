import Api from '../../api/Api';

export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export function login(username, password) {
    const userEmail = username.toLowerCase().split('@')[0] + '@fatergroup.com';
    return {
        type: LOGIN,
        payload: {
            promise: Api.login(userEmail, password),
        },
        meta: {
            username,
            email: userEmail
        }
    }
}
