import {persistReducer} from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import storage from 'redux-persist/lib/storage/session';
import {LOGIN, LOGOUT} from '../actions/Auth.actions';


const INITIAL_STATE = {
    user: null,
    error: false,
    loading: false
};
const AuthReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case `${LOGIN}_PENDING`:
            return {
                ...state,
                loading: true,
                error: null
            }
        case `${LOGIN}_FULFILLED`:
            return {
                ...state,
                user: {
                    email: action.meta.email,
                    username: action.meta.username,
                    token: action.payload
                },
                error: null,
                loading: false
            };
        case `${LOGIN}_REJECTED`:
            return {
                ...state,
                user: null,
                error: true,
                loading: false
            };
        case LOGOUT:
            return {
                ...state,
                user: null
            };
        default:
            return state;
    }
};

const persistConfig = {
    key: 'auth',
    whitelist: [
        'user'
    ],
    storage,
};
const persistedReducer = persistReducer(persistConfig, AuthReducer);

export default persistedReducer;
