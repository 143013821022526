import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {
    getFoodboxBooking,
    getMyBookings, sCanteenReservationActive, setCanteenReservationActive,
    setFoodboxReservationActive,
    sFoodboxBooking,
    sFoodboxReservationActive,
    sUserBookingsCanteen,
} from '../../store/actions';
import ReserveCanteen from '../ReserveCanteen';
import ReserveFoodbox from '../ReserveFoodbox';
import Canteen from './Canteen';
import FoodBox from './FoodBox';
import Header from './Header';


moment.locale('it');

export default function Layout() {
    const dispatch = useDispatch();
    const foodBoxBooking = useSelector(sFoodboxBooking);
    const canteenBooking = useSelector(sUserBookingsCanteen);
    const foodBoxReservationActive = useSelector(sFoodboxReservationActive);
    const canteenReservationActive = useSelector(sCanteenReservationActive);

    useEffect(function() {
        dispatch(getMyBookings());
        dispatch(getFoodboxBooking());
    }, []);

    return (
        <main
            className="bg-gray-100 dark:bg-gray-800 h-screen overflow-hidden relative">
            <div className="flex items-start justify-between">
                <div className="flex flex-col w-full pl-0 md:p-4 md:space-y-4">
                    <Header />
                    <div className="overflow-auto h-screen pb-24 pt-2 pr-2 pl-2 md:pt-0 md:pr-0 md:pl-0">
                        <div className="flex flex-col flex-wrap sm:flex-row ">
                            <div className="w-full sm:w-1/2">
                                <div className="mb-4">
                                    <div
                                        className="shadow-lg rounded-2xl bg-white dark:bg-gray-700 w-full">
                                        <div className="flex items-center p-4 justify-between">
                                            <p className="font-bold text-md text-black dark:text-white">
                                                Mensa
                                            </p>
                                            <button
                                                className="text-sm p-1 text-gray-400 border rounded border-gray-400 mr-4"
                                                onClick={() => dispatch(setCanteenReservationActive(true))}>
                                                Prenota mensa
                                            </button>
                                        </div>
                                        <ul>
                                            <li className="flex items-center text-gray-600 dark:text-gray-200 justify-between py-3 border-b-2 border-gray-100 dark:border-gray-800">
                                                <div className="flex flex-col justify-center items-start text-sm w-full p-4">
                                                    {canteenBooking.length === 0 && <span className="mx-4">Non ci sono prenotazioni per la mensa</span>}
                                                    {canteenBooking.length > 0 && canteenBooking.map(f => <Canteen key={f.id} canteen={f}></Canteen>)}
                                                </div>
                                            </li>
                                        </ul>
                                        <div
                                            className="flex items-center p-4 justify-between">
                                            <p className="font-bold text-md text-black dark:text-white">
                                                FoodBox
                                            </p>
                                            <button
                                                className="text-sm p-1 text-gray-400 border rounded border-gray-400 mr-4"
                                                onClick={() => dispatch(setFoodboxReservationActive(true))}
                                            >
                                                Prenota Foodbox
                                            </button>
                                        </div>

                                        <ul>
                                            <li className="flex items-center text-gray-600 dark:text-gray-200 justify-between py-3 border-b-2 border-gray-100 dark:border-gray-800">
                                                <div
                                                    className="flex items-center justify-start text-sm w-full">
                                                    <div className="flex flex-col justify-center items-start text-sm w-full p-4">
                                                        {foodBoxBooking.length === 0 && <span className="mx-4">Non ci sono foodbox prenotate</span>}
                                                        {foodBoxBooking.length > 0 && foodBoxBooking.map(f => <FoodBox key={f.id} foodbox={f}></FoodBox>)}
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full sm:w-1/2">
                                {foodBoxReservationActive && <div className="mb-4 mx-0 sm:ml-4 xl:mr-4">
                                    <ReserveFoodbox />
                                </div>}
                                {canteenReservationActive && <div className="mb-4 mx-0 sm:ml-4 xl:mr-4">
                                    <ReserveCanteen />
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

