import Api from '../../api/Api';
import {getMyBookings} from './Booking.actions';
import {sAccessToken} from './FoodBox.actions';

export const SET_CANTEEN_RESERVATION_ACTIVE = 'SET_CANTEEN_RESERVATION_ACTIVE';
export const FETCH_CANTEEN_CALENDAR = 'FETCH_CANTEEN_CALENDAR';
export const SET_CANTEEN_DAY_SELECTED = 'SET_CANTEEN_DAY_SELECTED';
export const FETCH_CANTEEN_FREE_SLOT = 'FETCH_CANTEEN_FREE_SLOT';
export const SET_CANTEEN_SLOT_SELECTED = 'SET_CANTEEN_SLOT_SELECTED';
export const FETCH_CANTEEN_FREE_SEATS = 'FETCH_CANTEEN_FREE_SEATS';
export const RESERVE_CANTEEN = 'RESERVE_CANTEEN';
export const DELETE_CANTEEN_RESERVATION = 'DELETE_CANTEEN_RESERVATION';

const sCanteen = state => state.canteen;
export const sCanteenReservationActive = state => sCanteen(state).canteenReservationActive;
export const sCanteenCalendar = state => sCanteen(state).canteenCalendar;
export const sCanteenDaySelected = state => sCanteen(state).canteenDaySelected;
export const sCanteenSlots = state => sCanteen(state).canteenSlots;
export const sCanteenSlotSelected = state => sCanteen(state).canteenSlotSelected;
export const sCanteenSeats = state => sCanteen(state).canteenSeats;
export const sCanteenSeatsFetching = state => sCanteen(state).canteenSeatsFetching;
export const sCanteenReserved = state => sCanteen(state).canteenReserved;

export function setCanteenReservationActive(active) {
    return {
        type: SET_CANTEEN_RESERVATION_ACTIVE,
        payload: active
    }
}

export function getCanteenCalendar() {
    return (dispatch, getState) => {
        const token = sAccessToken(getState());
        dispatch({
            type: FETCH_CANTEEN_CALENDAR,
            payload: Api.getCanteenCalendar(token)
        });
    }
}

export function setCanteenDaySelected(day) {
    return {
        type: SET_CANTEEN_DAY_SELECTED,
        payload: day
    }
}

export function getCanteenFreeSlot() {
    return (dispatch, getState) => {
        const state = getState();
        const token = sAccessToken(state);
        const {date} = sCanteenDaySelected(state);
        dispatch({
            type: FETCH_CANTEEN_FREE_SLOT,
            payload: Api.getCanteenDaySlots(token, date, 2)
        });
    }
}

export function setCanteenSlotSelected(slot) {
    return {
        type: SET_CANTEEN_SLOT_SELECTED,
        payload: slot
    }
}

export function getCanteenAvaiableSeats() {
    return async function(dispatch, getState) {
        const state = getState();
        const token = sAccessToken(state);
        const {date} = sCanteenDaySelected(state);
        const {id: slot_id} = sCanteenSlotSelected(state);
        return dispatch({
            type: FETCH_CANTEEN_FREE_SEATS,
            payload: Api.getCanteenAvailableSeats(token, date, slot_id, 3)
        }).then(function() {
            dispatch(bookCanteen()).then(function() {
                    dispatch(getMyBookings());
                setTimeout(function() {

                    dispatch(setCanteenReservationActive(false));
                }, 3000)
            });
        });
    }
}

export function bookCanteen() {
    return async function(dispatch, getState) {
        const state = getState();
        const token = sAccessToken(state);
        const {date} = sCanteenDaySelected(state);
        const {id: slot_id} = sCanteenSlotSelected(state);
        const seats = sCanteenSeats(state);

        if (seats && seats.length > 0) {
            return dispatch({
                type: RESERVE_CANTEEN,
                payload: Api.bookCanteen(token, {date, slot_id, office_id: 3})
            });
        }
    }
}
export function deleteCanteenBooking(reservation) {
    return (dispatch, getState) => {
        const token = sAccessToken(getState());
        dispatch({
            type: DELETE_CANTEEN_RESERVATION,
            payload: Api.deleteBooking(token, reservation)
        }).finally(function() {
            dispatch(getMyBookings());
            dispatch(setCanteenReservationActive(false));
        });
    };
}
