import {useSelector} from 'react-redux';
import Layout from './components/layout/Layout';
import {Login} from './pages/Login';
import {sAppInitialized} from './store/actions';

function App() {
    const user = useSelector(state => state.auth.user);
    const appInitialized = useSelector(sAppInitialized);

    if (appInitialized && user) {
        return <Layout />;
    }
    return <Login/>
}

export default App;
