/* global __DEV__ */

import { compose, createStore, applyMiddleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import promise from 'redux-promise-middleware';
import reducers from './reducers';

// TODO da controllare quando andremo in produzione
console.log("siamo in debug? " + __DEV__)
const composeEnhancers = (__DEV__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ :
    compose;

// const configureStore = (initialState) => {
//     return createStore(
//         reducers,
//         initialState,
//         composeEnhancers(
//             applyMiddleware(thunk, promise)
//         )
//     );
// };

export const store = configureStore({
    reducer: reducers,
    middleware: [thunk, promise]

});
export const persistor = persistStore(store, {}, () => {
    store.dispatch({ type: 'REHYDRATATION_COMPLETE' });
});
