import {ActionType} from 'redux-promise-middleware';
import {
    FETCH_CANTEEN_CALENDAR,
    FETCH_CANTEEN_FREE_SLOT,
    SET_CANTEEN_DAY_SELECTED,
    SET_CANTEEN_RESERVATION_ACTIVE,
    SET_CANTEEN_SLOT_SELECTED,
    FETCH_CANTEEN_FREE_SEATS, RESERVE_CANTEEN,
} from '../actions';

const INITIAL_STATE = {
    canteenReservationActive: false,
    canteenCalendar: [],
    canteenDaySelected: null,
    canteenSlots: [],
    canteenSlotSelected: null,
    canteenSeats: [],
    canteenSeatsFetching: false,
    canteenReserved: null,
};
const CanteenReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CANTEEN_RESERVATION_ACTIVE:
            return {
                ...state,
                canteenReservationActive: action.payload,
                canteenDaySelected: null,
                canteenSlots: [],
                canteenSlotSelected: null,
                canteenSeats: [],
                canteenSeatsFetching: false,
                canteenReserved: null,
            };
        case `${FETCH_CANTEEN_CALENDAR}_${ActionType.Fulfilled}`:
            return {
                ...state,
                canteenCalendar: action.payload,
                canteenDaySelected: null,
                canteenSlots: [],
                canteenSlotSelected: null,
                canteenSeats: [],
                canteenSeatsFetching: false,
                canteenReserved: null,
            };
        case SET_CANTEEN_DAY_SELECTED:
            return {
                ...state,
                canteenDaySelected: action.payload,
            };
        case `${FETCH_CANTEEN_FREE_SLOT}_${ActionType.Fulfilled}`:
            return {
                ...state,
                canteenSlots: action.payload,
            };
        case SET_CANTEEN_SLOT_SELECTED:
            return {
                ...state,
                canteenSlotSelected: action.payload,
            };
        case `${FETCH_CANTEEN_FREE_SEATS}_${ActionType.Pending}`:
            return {
                ...state,
                canteenSeatsFetching: true
            }
        case `${FETCH_CANTEEN_FREE_SEATS}_${ActionType.Rejected}`:
            return {
                ...state,
                canteenSeatsFetching: false
            }
        case `${FETCH_CANTEEN_FREE_SEATS}_${ActionType.Fulfilled}`:
            return {
                ...state,
                canteenSeats: action.payload,
                canteenSeatsFetching: false
            };
        case `${RESERVE_CANTEEN}_${ActionType.Fulfilled}`:
            return {
                ...state,
                canteenReserved: action.payload
            };
        default:
            return state;
    }
};

export default CanteenReducer;
