import moment from 'moment';
import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {deleteFoodboxBooking} from '../../store/actions';

export default function FoodBox({foodbox}) {
    const dateReservation = moment(foodbox.date).format('DD-MM-YYYY');
    const dispatch = useDispatch();
    const handleClickDelete = useCallback(function() {
        dispatch(deleteFoodboxBooking(foodbox));
    }, [foodbox]);
    return (
        <div className={"container w-full border-b-2 flex items-start justify-between"}>
            <div className={"flex items-start flex-col p-4 pb-2 justify-start w-full"}>
                <p className={"font-bold text-md text-black dark:text-white mb-2"}> {foodbox.foodBox_name} </p>
                <p className={"font-normal text-md text-black dark:text-white mb-2"}> {dateReservation} - {foodbox.foodbox_pickup_point.name} </p>
                <p className={"font-normal text-md text-black dark:text-white border-b-1 mb-2"}> {foodbox.foodbox_slot.start.substring(0,5)} - {foodbox.foodbox_slot.end.substring(0,5)} </p>
            </div>
            <div className={"pr-4 pt-4"}>
              <div style={{backgroundColor: '#1f2937', borderRadius: '50%', width: '35px', height: '35px'}} className={"flex items-center justify-center"}>
                <svg width="30" height="30" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg" style={{cursor: 'pointer'}} onClick={handleClickDelete}>
                  <path d="M39 12.0316L27.0201 24.0115L39 35.9913L35.9799 39.0115L24 27.0316L12.0201 39.0115L9 35.9913L20.9799 24.0115L9 12.0316L12.0201 9.01147L24 20.9913L35.9799 9.01147L39 12.0316Z" fill="#ffffff"/>
                </svg>
              </div>
            </div>
        </div>


    )
}
