import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {sCanteenSlots, setCanteenSlotSelected} from '../store/actions';

export default function CanteenSlotSelector({}) {
    const canteenSlots = useSelector(sCanteenSlots);
    const dispatch = useDispatch();
    return (
        <div className="mt-4 space-y-4">
            <p className="text-md text-black dark:text-white">
                Seleziona lo slot
            </p>
            {canteenSlots.map(
                i => {

                    return (
                        <div
                            key={i.id}
                            className="flex items-center">
                            <input id={`canteen-slot-${i.id}`}
                                   name={`canteen-slot`}
                                   type="radio"
                                   className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                   onChange={() => dispatch(setCanteenSlotSelected(i))}
                            />
                            <label
                                htmlFor={`canteen-slot-${i.id}`}
                                className="ml-3 block text-sm font-medium text-black dark:text-white"> {i.name} </label>
                        </div>
                    );
                })}
        </div>
    );
}
